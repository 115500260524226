import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "@layout";
import SEO from "@components/seo";
import PageBreadcrumb from "../../components/pagebreadcrumb";
import Profile from "../../components/Profile";
import { normalizedData } from "@utils/functions";

import jsonDataInit from "../../data/about/chiefconsultprofilejsondata.json";
import axios from "axios";
import { useEffect, useState } from "react";
import Preload from "../../components/preloadPage/preload";

const ChiefConsultantProfile = ({ data, location, pageContext }) => {
    const [jsonData, setJsonData] = useState(jsonDataInit);
    const [preload, setPreload] = useState(true);
    useEffect(() => {
        axios(
            "https://admin.reliance.com.bd/api/sendJSON/62bc2836cc8c4af79fa35adc"
        )
            .then((resultData) => {
                setJsonData(resultData.data);
                setPreload(false);
            })
            .catch((err) => {
                console.log(err);
                setPreload(false);
            });
    }, []);
    const globalContent = normalizedData(data?.allGeneral?.nodes || []);
    return (
        <Layout
            data={{
                ...globalContent["menu"],
                ...globalContent["footer"],
            }}
        >
            {preload && <Preload />}
            <SEO title="Chief Consultant's Profile" pathname="/" />
            <PageBreadcrumb
                pageContext={pageContext}
                location={location}
                title="Chief Consultant's Profile"
            />
            <Profile
                image={jsonData[0].image}
                name={jsonData[0].title1}
                designation={jsonData[0].title2}
                title={jsonData[0].title}
                disc1={jsonData[0].text}
                disc2={jsonData[0].text1}
                disc3={jsonData[0].text2}
                disc4={jsonData[1].title}
                disc5={jsonData[1].title1}
                disc6={jsonData[1].title2}
                disc7={jsonData[1].text}
            />
        </Layout>
    );
};
ChiefConsultantProfile.propTypes = {
    location: PropTypes.object,
    pageContext: PropTypes.object,
    data: PropTypes.shape({
        allGeneral: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        allArticle: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
    }),
};

export const query = graphql`
    query ChiefConsultantProfileQuery {
        allGeneral {
            nodes {
                section
                id
                menu {
                    ...Menu
                }
                footer {
                    ...Footer
                }
            }
        }
        allArticle {
            nodes {
                ...Articles
            }
        }
    }
`;

export default ChiefConsultantProfile;
